import { getPolkadotApi, getPolkadotSymbolsDecimals } from 'lib/polkadotApi'
import { fetcher } from 'lib/hooks/useCoinGecko'
import { BN_HUNDRED } from '@polkadot/util'
import * as Sentry from '@sentry/nextjs'
import { TokenCurrency } from 'lib/types'

export function getDefaultNetworkFeesUSD(token: TokenCurrency) {
  if (token === TokenCurrency.aUSD) {
    return 0.0056
  }
  return 1
}

/**
 * Get network fees related to on-ramp. Wrapper around getTokenTransferTxnFeeUSD
 * Prepares for if network fees include more than just fees related to token transfer
 */
export function getNetworkFeesUSD(amount: number, token: TokenCurrency) {
  if (token !== TokenCurrency.aUSD) {
    throw Error(
      `Token ${TokenCurrency[token]} not supported. Unable to estimate network fee`
    )
  }

  return getTokenTransferTxnFeeUSD(amount, token)
}

/**
 * Takes in an ACA value and returns the USD price equivalent
 */
export async function ACAToUSD(ACAAmount: number): Promise<number> {
  let priceFeed
  try {
    priceFeed = await fetcher()
  } catch (e) {
    Sentry.captureException(e)
  }

  // set default price if exchange is down
  const defaultACAPriceInUSD = 0.3

  if (priceFeed?.aca?.usd) {
    return ACAAmount * priceFeed.aca.usd
  } else {
    return ACAAmount * defaultACAPriceInUSD
  }
}

/**
 * Get fees related to transfering tokens from one account to another on the Acala chain
 *
 * Since the acala chain uses weight (amount of work required) to determine gas fees,
 * the exact wallet addresses and token amount should not matter.
 * Using placeholder values for these.
 */
export async function getTokenTransferTxnFeeAca(
  amount: number,
  token: TokenCurrency
): Promise<number> {
  if (token !== TokenCurrency.aUSD) {
    throw Error(
      `Token ${TokenCurrency[token]} not supported. Unable to estimate network fee`
    )
  }
  const defaultReceiverAddress =
    '5E4NF68vBzu6eg5azniN1Tau8g4iyMPY6YTvShqvKQSV6kzr'
  const defaultSenderAddress =
    '5HN15WNGeosycWVaXWGwNNAmrg16ZVHV2kJMKK5VPivbFWHK'
  const polkadotApi = await getPolkadotApi()
  await polkadotApi.isReady
  const polkadotSymbolsDecimals = await getPolkadotSymbolsDecimals(polkadotApi)
  const tokenSymbolDecimal =
    polkadotSymbolsDecimals[TokenCurrency[token].toUpperCase()]
  const tokenAmountSmallestDenomination = Number(
    amount * 10 ** tokenSymbolDecimal
  ).toFixed(0)
  const tx = polkadotApi.tx.currencies.transfer(
    defaultReceiverAddress,
    { TOKEN: TokenCurrency[token].toUpperCase() },
    tokenAmountSmallestDenomination
  )
  const paymentInfo = await tx.paymentInfo(defaultSenderAddress, { nonce: -1 })
  const networkfees = paymentInfo.partialFee
    .muln(110) // multiply current price by 1.1 to give us some spread
    .div(BN_HUNDRED)
    .toNumber()
  const networkfeesACA = networkfees / 10 ** polkadotSymbolsDecimals.ACA
  return networkfeesACA
}

/**
 * Get fees related to transfering tokens from one account to another
 */
export async function getTokenTransferTxnFeeUSD(
  amount: number,
  token: TokenCurrency
): Promise<number> {
  if (token !== TokenCurrency.aUSD) {
    throw Error(
      `Token ${TokenCurrency[token]} not supported. Unable to estimate network fee`
    )
  }
  const networkfeesACA = await getTokenTransferTxnFeeAca(amount, token)
  const networkfeesUSD = await ACAToUSD(networkfeesACA)
  return Number(networkfeesUSD.toFixed(7))
}
