import { Customer } from '@prisma/client'
import {
  isDev,
  preapprovedSandboxAccountDetails,
  primetrustFeesAccountId,
  primetrustSettlementAccountId
} from 'lib/config'

import { getClient } from 'lib/partner/primetrust'
import {
  IContactUpdate,
  ISandboxOpenResponse
} from 'lib/partner/primetrust/interfaces'

import { waitSleep } from '.'
import { getTotalFeesRoundedUSD } from '../fees'
import {
  AccountStatus,
  KYCAccountData,
  KYCRequiredActions,
  Fees
} from 'lib/types'

async function isTransferAuthorized(
  fromAccountNumber: string,
  toAccountId: string
): Promise<boolean> {
  const primetrustClient = await getClient()
  const accountTransferAuthorization =
    await primetrustClient.GetAccountTransferAuthorization({
      'from-account-number': fromAccountNumber
    })

  const accountTransferAuthorizations = accountTransferAuthorization.data
  if ((accountTransferAuthorizations as Array<any>).length === 0) {
    return false
  }

  for (const authorization of accountTransferAuthorizations) {
    if (authorization.relationships['to-account'].data.id === toAccountId) {
      return true
    }
  }
  return false
}

export async function instantSettlement(
  receivingAccountId: string,
  amount: number
) {
  const primetrustClient = await getClient()
  const account = await primetrustClient.GetAccount(receivingAccountId)
  const settlementAccount = await primetrustClient.GetAccount(
    primetrustSettlementAccountId
  )

  const isSettlementToAccountTransferAuthorized = await isTransferAuthorized(
    settlementAccount.data.attributes.number,
    account.data.id
  )

  if (!isSettlementToAccountTransferAuthorized) {
    const accountTransferAuthorizationRequest = {
      'to-account-id': account.data.id,
      'from-account-name': settlementAccount.data.attributes.name,
      'from-account-number': settlementAccount.data.attributes.number
    }

    const createAuthorization =
      await primetrustClient.CreateAccountTransferAuthorization(
        accountTransferAuthorizationRequest
      )
  }

  const accountCashTransferRequest = {
    amount: amount.toString(),
    'currency-type': 'USD',
    'from-account-id': primetrustSettlementAccountId,
    'to-account-id': account.data.id,
    reference: `${account.data.attributes.number}`
  }

  const accountCashTransfer = await primetrustClient.CreateAccountCashTransfer(
    accountCashTransferRequest
  )

  const accountCashTransferId = accountCashTransfer.data.id

  // approve account cash transfer: SANDBOX
  if (isDev) {
    const approveCashTransfer =
      await primetrustClient.SandboxApproveCashTransfer({
        accountCashTransferId
      })

    console.log('approveCashTransfer', approveCashTransfer)
  }
}

export async function transferFeesToFeesAccount(
  customerAccountId: string,
  fees: Fees
) {
  const primetrustClient = await getClient()
  const customerAccount = await primetrustClient.GetAccount(customerAccountId)
  const feesAccount = await primetrustClient.GetAccount(primetrustFeesAccountId)

  const isCustomerToFeesAccountTransferAuthorized = await isTransferAuthorized(
    customerAccount.data.attributes.number,
    feesAccount.data.id
  )

  if (!isCustomerToFeesAccountTransferAuthorized) {
    const accountTransferAuthorizationRequest = {
      'to-account-id': feesAccount.data.id,
      'from-account-name': customerAccount.data.attributes.name,
      'from-account-number': customerAccount.data.attributes.number
    }

    const createAuthorization =
      await primetrustClient.CreateAccountTransferAuthorization(
        accountTransferAuthorizationRequest
      )
  }

  const totalFeesToCharge = getTotalFeesRoundedUSD(fees)

  const accountCashTransferRequest = {
    amount: totalFeesToCharge.toString(),
    'currency-type': 'USD',
    'from-account-id': customerAccount.data.id,
    'to-account-id': feesAccount.data.id,
    reference: ``
  }

  const accountCashTransfer = await primetrustClient.CreateAccountCashTransfer(
    accountCashTransferRequest
  )

  const accountCashTransferId = accountCashTransfer.data.id

  // approve account cash transfer: SANDBOX
  if (isDev) {
    const approveCashTransfer =
      await primetrustClient.SandboxApproveCashTransfer({
        accountCashTransferId
      })

    console.log('approveCashTransfer', approveCashTransfer)
  }
}

/**
 * Represents a pending kyc account data.
 * Useful for mocks or as a dummy wait
 * response
 */
export const pendingKYCAccountData = {
  account: {
    status: AccountStatus.Pending
  },
  contacts: {
    kycRequiredActions: [],
    cipCleared: false,
    amlCleared: false,
    documentsCleared: false
  },
  checks: []
}

/**
 * Has account been opened? If so, we're open
 * for business and can start transacting
 *
 * @param data KYCAccountData
 * @returns boolean whether account is open for business
 */
export function isAccountOpen(data: KYCAccountData) {
  const { account } = data
  return account.status === 'opened'
}

/**
 * Has account been closed? Note this is not
 * a terminal state. Manual review can allow us
 * to reopen the account.
 *
 * @param data KYCAccountData
 * @returns boolean whether account is closed
 */
export function isAccountClosed(data: KYCAccountData) {
  const { account } = data
  return account.status === 'closed'
}

/**
 * Has account gone through KYC verification?
 * Separate from account opening; checks whether
 * we've passed all kyc checks required to open
 * account
 *
 * @param data KYCAccountData
 * @returns true if kyc data indicates account has passed kyc checks
 */
export function isKycVerified(data: KYCAccountData) {
  const { contacts } = data

  if (Object.keys(contacts.kycRequiredActions).length > 0) {
    return false
  }

  return contacts.cipCleared && contacts.amlCleared && contacts.documentsCleared
}

/**
 * Gets the required kyc checks as a list of
 * human-readable strings
 *
 * @param data KYCAccountData
 * @returns list of human-readable required instructions
 */
export function getKycRequiredActions(
  data: KYCAccountData
): KYCRequiredActions[] {
  const { contacts } = data
  return contacts.kycRequiredActions
}

/**
 * Returns any pending checks. Usually you don't have
 * to use this, but helpful for debugging and internal
 * operations
 *
 * @param data KYCAccountData
 * @returns list of pending checks
 */
export function getPendingChecks(data: KYCAccountData) {
  const { checks } = data
  return checks.filter(
    (check) =>
      check.attributes.status === 'pending' ||
      check.attributes.status === 'denied'
  )
}

/**
 * Automatically approves all checks and opens account.
 * Only for use in Sandbox
 *
 * @param accountId string
 * @returns ISandboxOpenResponse from the opening of account
 */
export async function sandboxApproveAccount(
  accountId: string
): Promise<ISandboxOpenResponse | undefined> {
  if (!isDev) {
    console.error('This function only runs in the dev env')
    return
  }
  const client = await getClient(isDev)
  const account = await client.GetAccount(accountId)
  const contactId = account.primaryContact.id
  const tracKycRes = await client.TrackKYCProcess(contactId)

  console.log('Sandbox - Clearing checks')
  tracKycRes.included.forEach(async (r) => {
    if (r.type === 'cip-checks') {
      await client.SandboxApproveCIP(r.id)
    }
    if (r.type === 'kyc-document-checks') {
      await client.SandboxVerifyKYCDocument(r.id)
    }
    if (r.type === 'aml-checks') {
      await client.SandboxVerifyKYCDocument(r.id)
    }
  })
  try {
    // Sometimes account status takes a while to set to 'pending' after clearing checks, wait for a while before opening account
    console.log('Opening account')
    await waitSleep(2000)
    const openAccountRes = await client.SandboxOpenAccount(accountId)
    return openAccountRes
  } catch (e) {
    // If there is an error, just wait. Accounts should automatically open if all checks are clear in ~20secs
  }
}

export async function sandboxOpenApprovedAccount(
  accountId: string
): Promise<ISandboxOpenResponse | undefined> {
  if (!isDev) {
    console.error('This function only runs in the dev env')
    return
  }
  const client = await getClient(isDev)
  try {
    // Sometimes account status takes a while to set to 'pending' after clearing checks, wait for a while before opening account
    console.log('Opening account')
    await waitSleep(2000)
    const openAccountRes = await client.SandboxOpenAccount(accountId)
    return openAccountRes
  } catch (e) {
    // If there is an error, just wait. Accounts should automatically open if all checks are clear in ~20secs
  }
}

/**
 * Reconciles account data and make sure that it aligns with what we saved in our db
 * Useful when we mutate fields to bypass Primetrust randomly closing accounts
 * Only for use in Sandbox
 *
 * @param accountId string
 * @param customer Customer object from database to reconcile data with
 * @returns ISandboxOpenResponse from the opening of account
 */
export async function sandboxRevertAutoApproveFields(
  accountId: string,
  customer: Customer
) {
  if (!isDev) {
    console.error('This function only runs in the dev env')
    return
  }
  const client = await getClient(isDev)
  const account = await client.GetAccount(accountId)
  const contactId = account.primaryContact.id

  console.log('Updating user email for account')
  const infoToUpdate: IContactUpdate = {
    'contact-type': account.primaryContact.attributes['contact-type'],
    email: customer.email,
    name: customer.name
  }

  await client.UpdateContact(contactId, infoToUpdate)
}

/**
 * Checks if details matches some preapproved details.
 * Used for automatic approve in sandbox
 * @param firstName
 * @param lastName
 * @param dateOfBirth
 */
export function checkMatchesPreapprovedDetails(
  firstName: string,
  lastName: string,
  dateOfBirth: string | Date
): boolean {
  if (!isDev) {
    return false
  }
  for (const details of preapprovedSandboxAccountDetails) {
    if (firstName !== details.firstName) {
      return false
    }

    if (lastName !== details.lastName) {
      return false
    }

    if (
      typeof dateOfBirth === 'string' &&
      dateOfBirth !== details.dateOfBirth
    ) {
      return false
    }

    if (dateOfBirth instanceof Date) {
      const preApprovedDate = new Date(details.dateOfBirth)
      if (dateOfBirth > preApprovedDate) {
        return false
      } else if (dateOfBirth < preApprovedDate) {
        return false
      }
    }
  }

  return true
}

/**
 * Checks if details of account id matches some preapproved details.
 * Used for automatic approve in sandbox
 * @param accountId
 */
export async function checkAccountIdForPreapprovedDetails(
  accountId: string
): Promise<boolean> {
  if (!isDev) {
    return false
  }
  const client = await getClient(isDev)
  const account = await client.GetAccount(accountId)
  const contact = account.primaryContact

  const isPreapproved = checkMatchesPreapprovedDetails(
    contact.attributes['first-name'],
    contact.attributes['last-name'],
    contact.attributes['date-of-birth']
  )

  return isPreapproved
}
