import { PlaidEnvironments } from 'plaid'

export const isServer = typeof window === 'undefined'

export const isDev =
  process.env.VERCEL_ENV === 'development' ||
  process.env.VERCEL_ENV === 'preview' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
  process.env.NODE_ENV === 'development'

export const isLocal = process.env.NODE_ENV === 'development'

// Just the domain portion of the URL
export const domain =
  process.env.NEXT_PUBLIC_VERCEL_URL || 'supercharge-finance.vercel.app'

// Use these for constructing urls for webhooks, etc
export const port = getEnv('PORT', '8080')
export const host = process.env.NEXT_PUBLIC_VERCEL_URL
  ? `https://${domain}`
  : `http://localhost:${port}`

export const apiBaseUrl = `${host}/api`

export const primetrustAdminAccountId: string =
  process.env.PRIMETRUST_ADMIN_ACCOUNT_ID || ''

export const primetrustSettlementAccountId: string =
  process.env.PRIMETRUST_ADMIN_SETTLEMENT_ACCOUNT_ID || ''

export const primetrustFeesAccountId: string =
  process.env.PRIMETRUST_ADMIN_SETTLEMENT_ACCOUNT_ID || ''

export const fathomId = isDev ? null : process.env.NEXT_PUBLIC_FATHOM_ID

const excludeLocalFathomConfig = {
  excludedDomains: ['localhost', 'localhost:8080']
}

export const fathomConfig = fathomId ? excludeLocalFathomConfig : undefined

export const stripeSecretKey = process.env.STRIPE_SECRET_KEY || ''

export const primeTrustWebhookUrl =
  process.env.PRIMETRUST_WEBHOOK_URL || `https://${host}/api/primetrust/webhook`

export const supportEmail = 'hello@supercharge.finance'

// ----------------------------------------------------------------------------

export function getEnv(
  key: string,
  defaultValue?: string,
  env = process.env
): string {
  const value = env[key]

  if (value !== undefined) {
    return value
  }

  if (defaultValue !== undefined) {
    return defaultValue
  }

  throw new Error(`Config error: missing required env variable "${key}"`)
}

export const slackWebhookUrl = getEnv('SLACK_WEBHOOK_URL', '')

export const cardServiceFeePercent = 4.0
export const achServiceFeePercent = 1.5
export const firstTimeVerificationFeeUSD = 3.0
export const firstTimeCardFeeUSD = 0.0
export const firstTimeACHFeeUSD = 3.0

export const minAmount = 10
export const maxAmount = 100

// ---------------------------------------------------------------------------

export const awsAccessKeyId = getEnv('SUPERCHARGE_AWS_ACCESS_KEY_ID', '')

export const awsSecretAccessKey = getEnv(
  'SUPERCHARGE_AWS_SECRET_ACCESS_KEY',
  ''
)

export const awsDocumentUploadBucket = getEnv(
  'SUPERCHARGE_AWS_DOCUMENT_UPLOAD_BUCKET',
  ''
)

export const megabyte = 1048576

export const awsImageUploadMaxSizeBytes = megabyte * 5

export const awsPresignedUrlExpirationSeconds = 60 * 5

export const POACipherKeySecret = getEnv('POA_CIPHER_SECURITY_KEY', '')

export const POACipherInitVector = getEnv('POA_CIPHER_INIT_VECTOR', '')

export const POAHashSecret = getEnv('POA_HASH_SECRET', '')

// ---------------------------------------------------------------------------

export const usdtProdAssetId = '25311c35-26d0-4cf1-b916-80f377a7e468'

export const primeTrustScriptSrc = isDev
  ? 'https://sandbox.bootstrapper.primetrust-cdn.com/bootstrap.js'
  : 'https://bootstrapper.primetrust-cdn.com/bootstrap.js'

export const primeTrustPurchaseProtectionScriptSrc = isDev
  ? 'https://sandbox.bootstrapper.primetrust-cdn.com/purchaseProtection.js'
  : 'https://bootstrapper.primetrust-cdn.com/purchaseProtection.js'

export const primeTrustPurchaseProtectionId = isDev
  ? '071fe2143248'
  : '66de8cbce68d'

export const plaidEnvironment = isDev
  ? PlaidEnvironments.sandbox
  : PlaidEnvironments.production

export const buyerServer = isDev
  ? 'https://sandbox-buyer-server.onrender.com'
  : 'https://production-buyer-server.onrender.com'

export const buyerServerAuthUrl = isDev
  ? 'https://supercharge-finance-staging.us.auth0.com'
  : 'https://supercharge-finance.us.auth0.com'

export const wssNodeEndpoint = isDev
  ? 'wss://acala-mandala-adapter.api.onfinality.io/public-ws'
  : 'wss://acala-polkadot.api.onfinality.io/public-ws'

export const acalaSubscanUrl = isDev
  ? 'https://acala-testnet.subscan.io'
  : 'https://acala.subscan.io'

export const socureSDKEndpoint = 'https://websdk.socure.com/bundle.js'

export const socureDeviceRiskEndpoint = 'https://js.dvnfo.com/devicer.min.js'

export const socureSDKApiKey = process.env
  .NEXT_PUBLIC_SOCURE_SDK_API_KEY as string

export const buyerClientId = getEnv('BUYER_CLIENT_ID', '')
export const buyerClientSecret = getEnv('BUYER_CLIENT_SECRET', '')

export const availableTokens = ['aUSD']

// ---------------------------------------------------------------------------

export const preapprovedSandboxAccountDetails = [
  {
    firstName: 'John',
    lastName: 'Jones',
    dateOfBirth: '1990-01-01'
  }
]
