import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import FormData from 'form-data'
import stream from 'stream'

import {
  ICreateUserResponse,
  IContactUpdate,
  IGetUsersResponse,
  IGetCurrentUserResponse,
  ICreateAccountResponse,
  ICreateCreditCardResourceResponse,
  IGetAccountCryptoBalanceResponse,
  IGetAccountFiatBalanceResponse,
  IUploadDocumentResponse,
  IGetAccountsResponse,
  IOwner,
  IContactsResponseWithChecks,
  IAccountUpdates,
  IQuoteRequest,
  IInternalAssetTransferRequest,
  ISandboxFundResponse,
  ISandboxKYCCIPApprove,
  ISandboxKYCDocumentChecksApprove,
  IGetContactsResponse,
  ISandboxOpenResponse,
  IGetUploadedDocumentsResponse,
  ICreateAssetDisbursement,
  IExecuteQuoteResponse,
  IRequestQuoteResponse,
  ISandboxAMLApproveResponse,
  ISandboxGetCreditCardResourceResponse,
  IGetCreditCardResourcesResponse,
  ICreditCardResourceObject,
  IGetFundsTransferMethodsResponse,
  IGetFundsTransferMethodsCreditCardResourceIncludedResponse,
  IGetContactSingleContactAttributes,
  IKYCDocumentCheckResource,
  ICIPCheckResource,
  IAMLCheckResource,
  IKYCCreateDocumentCheckResponse,
  IGetAccountResponse,
  IGetCreditCardResourceGivenIdResponse,
  ICreateContributionResponse,
  ICreateFundsTransferMethodResponse,
  IGetAccountsFundsTransfersResponse,
  FundsTransfer,
  IGetAccountsWebhookConfigResponse,
  IGetFundsTransferResponse,
  ICreateAccountTransferAuthorizationRequest,
  ICreateAccountCashTransferRequest,
  IGetAccountTransferAuthorizationRequest,
  IGetAccountTransferAuthorizationResponse,
  IGetContributionResponse,
  IGetAssetsResponse,
  IGetAssetTransferMethodsResponse,
  IGetAssetDisbursement,
  ICreateDisbursement,
  AssetsTransfer,
  IGetAccountsAssetsTransfersResponse,
  WebhookConfig
} from './interfaces'
import { unwrapIncluded } from './utils'

const PRIMETRUST_SANDBOX_API_URL = 'https://sandbox.primetrust.com'
const PRIMETRUST_API_URL = 'https://api.primetrust.com'
const PRIMETRUST_API_VERSION = 'v2'

const USERS_API_ENDPOINT = '/users'
const ACCOUNTS_API_ENDPOINT = '/accounts'
const CONTACTS_API_ENDPOINT = '/contacts'
const QUOTES_API_ENDPOINT = '/quotes'
const INTERNAL_REQUESTS_API_ENDPOINT = '/internal-asset-transfers'
const ACCOUNT_TRANSFER_AUTHORIZATIONS_API_ENDPOINT =
  '/account-transfer-authorizations'

const ACCOUNT_CASH_TRANSFER_API_ENDPOINT = '/account-cash-transfers'
const ASSET_TRANSFER_API_ENDPOINT = '/asset-transfers'
const UPLOADED_DOCUMENTS_ENDPOINT = '/uploaded-documents'
const KYC_DOCUMENT_CHECKS_ENDPOINT = `/kyc-document-checks`
const AML_CHECKS_ENDPOINT = `/aml-checks`
const CIP_CHECKS_ENDPOINT = `/cip-checks`
const RESOURCE_TOKENS_ENDPOINT = `/resource-tokens`
const AGREEMENT_PREVIEW_ENDPOINT = `/agreement-previews`

const USER_ENTITY_TYPE = 'user'
const ACCOUNT_ENTITY_TYPE = 'account'
const CONTACT_ENTITY_TYPE = 'contacts'

const HTTP_POST_METHOD = 'post'
const HTTP_DELETE_METHOD = 'delete'
const HTTP_PATCH_METHOD = 'patch'

const DOCUMENT_TYPE_DRIVERS_LICENSE = 'drivers_license'

// exportable config

export const ResourceTypeAccount = 'account'
export const ResourceTypeAccountCashTransfer = 'account-cash-transfers'
export const ResourceTypeFundsTransfers = 'funds-transfers'
export const ResourceTypeDisbursementAuthorizations =
  'disbursement-authorizations'
export const ResourceTypeAssetTransfers = 'asset-transfers'

export enum FundsTransferStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Settled = 'settled',
  Reversed = 'reversed'
}

export const mockUser = {
  'contact-type': 'natural_person',
  'first-name': 'John',
  'last-name': 'Doe',
  email: 'jim@supercharge.finance',
  'tax-id-number': '057855555',
  'tax-country': 'US',
  'date-of-birth': '1990-01-01',
  sex: 'male',
  'primary-phone-number': {
    country: 'US',
    number: '+1 201-223-7711',
    sms: true
  },
  'primary-address': {
    'street-1': '12 Washington Street',
    'street-2': '',
    'postal-code': '07024',
    region: 'NJ',
    city: 'Englewood',
    country: 'US'
  }
}

// Custom error class
export class PrimeTrustApiError extends Error {
  isPrimeTrustApiError = true
  response: AxiosResponse
  errorsAsJson: string

  constructor(message: string, response: AxiosResponse, errorsAsJson: string) {
    super(message)
    this.response = response
    this.errorsAsJson = errorsAsJson
    this.name = new.target.prototype.constructor.name
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export function isPrimeTrustApiError(
  payload: any
): payload is PrimeTrustApiError {
  return payload?.isPrimeTrustApiError === true
}

export class PrimeTrustAPIClient {
  public name: string
  public version: string
  public rootURL: string
  public token = ''
  private options: {
    username: string
    password: string
    sandbox: boolean
  }

  constructor(options: {
    username: string
    password: string
    sandbox: boolean
  }) {
    this.name = 'PrimeTrustAPIClient'
    this.version = 'v2'
    this.rootURL = options.sandbox
      ? PRIMETRUST_SANDBOX_API_URL
      : PRIMETRUST_API_URL
    this.options = options
  }

  private async request(config: AxiosRequestConfig): Promise<any> {
    try {
      this.token = this.token
        ? this.token
        : await this.getJWT(this.options.username, this.options.password)
      config.headers = {
        Authorization: `Bearer ${this.token}`,
        ...config.headers
      }

      config.baseURL = `${this.rootURL}/${PRIMETRUST_API_VERSION}`
      const response = await axios(config)

      return response.data
    } catch (error) {
      // if is axios error, then we've hit the api
      if (axios.isAxiosError(error) && error.response) {
        throw new PrimeTrustApiError(
          'PrimeTrust Api Error',
          error.response,
          JSON.stringify(error.response.data.errors || [], null, 2)
        )
      }

      // we never got a response, rethrow error
      throw error
    }
  }

  public async getJWT(username: string, password: string) {
    const url = `/auth/jwts`
    const method = HTTP_POST_METHOD
    const auth = {
      username,
      password
    }
    const baseURL = `${this.rootURL}`
    const response = await axios({ url, method, auth, baseURL })

    return response.data.token
  }

  // USER

  public async CreateUser(
    email: string,
    name: string,
    password: string
  ): Promise<ICreateUserResponse> {
    const url = USERS_API_ENDPOINT
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: USER_ENTITY_TYPE,
        attributes: { email, name, password }
      }
    }

    return this.request({ url, method, data })
  }

  public async GetUsers(): Promise<IGetUsersResponse> {
    const url = USERS_API_ENDPOINT

    return this.request({ url })
  }

  public async GetCurrentUser(): Promise<IGetCurrentUserResponse> {
    const url = `${USERS_API_ENDPOINT}/current`
    return this.request({ url })
  }

  // DOCUMENTS

  public async GetUploadedDocuments(): Promise<IGetUploadedDocumentsResponse> {
    const url = UPLOADED_DOCUMENTS_ENDPOINT
    return this.request({ url })
  }

  public async UploadDocument(document: {
    contactId: string
    label: string
    fileData: stream.Readable | Buffer | string
    mimeType: string
    filename?: string
  }): Promise<IUploadDocumentResponse> {
    const form = new FormData()
    form.append('label', document.label)
    form.append('contact-id', document.contactId)
    form.append('file', document.fileData, { filename: document.filename })
    form.append('extension', document.mimeType)
    try {
      const result = await axios.post(
        `${this.rootURL}/${PRIMETRUST_API_VERSION}/uploaded-documents`,
        form,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data',
            ...form.getHeaders()
          }
        }
      )

      return result.data as any
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify((error as any).response.data))

      return (error as any).response.data
    }
  }

  // AGREEMENT

  public getContactName(firstName: string, lastName: string): string {
    return `${firstName} ${lastName}`
  }

  public getAccountName(firstName: string, lastName: string): string {
    return `${firstName} ${lastName} Account`
  }

  public async GetAgreementPreview(owner: IOwner): Promise<any> {
    try {
      const url = AGREEMENT_PREVIEW_ENDPOINT
      const method = HTTP_POST_METHOD
      delete owner.frontImg
      delete owner.backImg

      const contactName = this.getContactName(
        owner['first-name'],
        owner['last-name']
      )

      const data = {
        data: {
          type: 'account',
          attributes: {
            'account-type': 'custodial',
            name: `${contactName} Account`,
            'authorized-signature': contactName,
            owner
          }
        }
      }

      return this.request({ url, method, data })
    } catch (e) {
      console.log(e)
    }
  }

  // ACCOUNT

  public async CreateInstantSettlementAccount(
    name: string
  ): Promise<ICreateAccountResponse> {
    const data = {
      data: {
        type: ACCOUNT_ENTITY_TYPE,
        attributes: {
          'account-type': 'custodial',
          name,
          'authorized-signature': name
        }
      }
    }

    const url = ACCOUNTS_API_ENDPOINT
    const method = HTTP_POST_METHOD

    return this.request({ url, method, data })
  }

  public async CreateAccount(
    owner: IOwner,
    webhookConfig?: WebhookConfig
  ): Promise<ICreateAccountResponse> {
    const contactName = this.getContactName(
      owner['first-name'],
      owner['last-name']
    )

    const accountName = this.getAccountName(
      owner['first-name'],
      owner['last-name']
    )

    const data = {
      data: {
        type: ACCOUNT_ENTITY_TYPE,
        attributes: {
          'account-type': 'custodial',
          name: accountName,
          'authorized-signature': contactName,
          'webhook-config': webhookConfig || {},
          owner
        }
      }
    }

    // checks against the naem
    if (!accountName.endsWith('Account')) {
      throw new Error('Account name must end with "Account"')
    }

    const url = ACCOUNTS_API_ENDPOINT
    const method = HTTP_POST_METHOD

    return this.request({ url, method, data })
  }

  public async GetAccountByName(name: string): Promise<IGetAccountResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}/?filter[name eq]=${name}`
    const account = await this.request({ url })
    return account
  }

  public async GetAccount(accountId: string): Promise<IGetAccountResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}/${accountId}?include=contacts`
    const account = await this.request({ url })

    const contacts = unwrapIncluded<IGetContactSingleContactAttributes>(
      account,
      'contacts'
    )

    const mostRecentContacts = contacts.sort((a, b) => {
      if (a.attributes['created-at'] > b.attributes['created-at']) {
        return -1
      } else {
        return 1
      }
    })

    return {
      primaryContact: mostRecentContacts[0],
      contacts,
      ...account
    }
  }

  public async GetAccounts(): Promise<IGetAccountsResponse> {
    const url = ACCOUNTS_API_ENDPOINT
    return this.request({ url })
  }

  public async GetOpenedAccounts(): Promise<IGetAccountsResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}?filter[status eq]=opened&`
    return this.request({ url })
  }

  public async GetAccountsWithLink(url: string): Promise<IGetAccountsResponse> {
    return this.request({ url })
  }

  public async GetAccountTransferAuthorization(
    request: IGetAccountTransferAuthorizationRequest
  ): Promise<IGetAccountTransferAuthorizationResponse> {
    const url = `${ACCOUNT_TRANSFER_AUTHORIZATIONS_API_ENDPOINT}?filter[from-account-number eq]=${request['from-account-number']}&include=to-account`
    return this.request({ url })
  }

  public async UpdateAccount(accountId: string, updates: IAccountUpdates) {
    const url = `${ACCOUNTS_API_ENDPOINT}/${accountId}`
    const method = HTTP_PATCH_METHOD
    const data = {
      data: {
        type: 'accounts',
        attributes: updates
      }
    }
    return this.request({ url, method, data })
  }

  public async GetAccountFundsTransfers(
    accountId: string
  ): Promise<IGetAccountsFundsTransfersResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}/${accountId}?include=funds-transfers`
    const account = await this.request({ url })

    const fundsTransfers = unwrapIncluded<FundsTransfer>(
      account,
      'funds-transfers'
    )

    return {
      fundsTransfers,
      ...account
    }
  }

  public async GetAccountAssetsTransfers(
    accountId: string
  ): Promise<IGetAccountsAssetsTransfersResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}/${accountId}?include=asset-transfers`
    const account = await this.request({ url })

    const assetsTransfers = unwrapIncluded<AssetsTransfer>(
      account,
      'asset-transfers'
    )

    return {
      assetsTransfers,
      ...account
    }
  }

  public async GetAccountWebhookConfigs(
    accountId: string
  ): Promise<IGetAccountsWebhookConfigResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}/${accountId}?include=webhook-config`
    const account = await this.request({ url })

    const webhookConfigs = unwrapIncluded<WebhookConfig>(
      account,
      'webhook-configs'
    )

    return {
      webhookConfigs,
      ...account
    }
  }

  public async GetAccountFiatBalance(
    id: string
  ): Promise<IGetAccountFiatBalanceResponse> {
    const url = `/account-cash-totals?account.id=${id}`
    return this.request({ url })
  }

  public async GetAccountCryptoBalance(
    id: string
  ): Promise<IGetAccountCryptoBalanceResponse> {
    const url = `/account-asset-totals?account.id=${id}`

    return this.request({ url })
  }

  public async GetAccountContacts(id: string): Promise<any> {
    const url = `${CONTACTS_API_ENDPOINT}?account.id=${id}`

    return this.request({ url })
  }

  // ACCOUNT TRANSFER

  public async CreateAccountTransferAuthorization(
    request: ICreateAccountTransferAuthorizationRequest
  ) {
    const url = `${ACCOUNT_TRANSFER_AUTHORIZATIONS_API_ENDPOINT}`
    const method = HTTP_POST_METHOD

    const data = {
      data: {
        type: 'account-transfer-authorizations',
        attributes: {
          ...request
        }
      }
    }

    return this.request({ url, method, data })
  }

  public async CreateAccountCashTransfer(
    request: ICreateAccountCashTransferRequest
  ) {
    const url = `${ACCOUNT_CASH_TRANSFER_API_ENDPOINT}`
    const method = HTTP_POST_METHOD

    const data = {
      data: {
        type: 'account-cash-transfers',
        attributes: {
          ...request
        }
      }
    }

    return this.request({ url, method, data })
  }

  public async GetAssetTransfer(assetTransferId: string) {
    const url = `${ASSET_TRANSFER_API_ENDPOINT}/${assetTransferId}`
    return this.request({ url })
  }

  // CONTACT

  public async GetContactsByEmail(
    email: string
  ): Promise<IGetContactsResponse> {
    const url = `${CONTACTS_API_ENDPOINT}?filter[email]=${email}`
    return this.request({ url })
  }

  public async CreateContact(accountId: string, owner: IOwner) {
    const data = {
      data: {
        type: CONTACT_ENTITY_TYPE,
        attributes: {
          'account-id': accountId,
          'account-roles': ['owner'],
          ...owner
        }
      }
    }

    const url = CONTACTS_API_ENDPOINT
    const method = HTTP_POST_METHOD

    return this.request({ url, method, data })
  }

  public async GetContacts(
    includeAccount = false
  ): Promise<IGetContactsResponse> {
    const url = includeAccount
      ? `${CONTACTS_API_ENDPOINT}?include=account`
      : CONTACTS_API_ENDPOINT
    return this.request({ url })
  }

  public async GetContact(id: string): Promise<IGetContactsResponse> {
    const url = `${CONTACTS_API_ENDPOINT}/${id}`
    return this.request({ url })
  }

  public async UpdateContact(id: string, updateData: IContactUpdate) {
    const url = `${CONTACTS_API_ENDPOINT}/${id}`
    const method = HTTP_PATCH_METHOD
    const data = {
      data: {
        type: 'contacts',
        attributes: {
          ...updateData,
          'contact-type': 'natural_person'
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async DeleteContact(id: string): Promise<IGetContactsResponse> {
    const url = `${CONTACTS_API_ENDPOINT}/${id}`
    const method = HTTP_DELETE_METHOD
    return this.request({ url, method })
  }

  // ASSET

  public async GetAssets(): Promise<IGetAssetsResponse> {
    const url = '/assets'
    return this.request({ url })
  }

  public async GetAssetById(assetId: string): Promise<IGetAssetsResponse> {
    const url = `/assets?filter[id eq]=${assetId}`
    return this.request({ url })
  }

  public async GetAssetByName(assetName: string): Promise<IGetAssetsResponse> {
    const url = `/assets?filter[unit-name eq]=${assetName}`
    return this.request({ url })
  }

  // RESOURCE

  public async CreateResourceTokens(userId: string): Promise<any> {
    const url = RESOURCE_TOKENS_ENDPOINT
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'resource-tokens',
        attributes: {
          'resource-type': 'user',
          'resource-id': userId,
          'resource-token-type': 'create_account',
          data: {
            account_types: ['custodial'],
            contact_types: ['natural_person']
          }
        }
      }
    }

    return this.request({ url, method, data })
  }

  // CREDIT CARD RESOURCE

  public async GetCreditCardResources(): Promise<IGetCreditCardResourcesResponse> {
    const url = `/credit-card-resources`
    return this.request({ url })
  }

  public async GetCreditCardResourcesGivenId(
    creditCardResourceId: string
  ): Promise<IGetCreditCardResourceGivenIdResponse> {
    const url = `/credit-card-resources/${creditCardResourceId}`
    return this.request({ url })
  }

  public async GetVerifiedCreditCardResourcesByContact(
    contactId: string
  ): Promise<ICreditCardResourceObject[]> {
    const res = await this.GetCreditCardResources()
    const resultset = res.data.filter(
      (resource) => resource.attributes['contact-id'] === contactId
    )
    return resultset
  }

  public async CreateCreditCardResource(
    contactId: string
  ): Promise<ICreateCreditCardResourceResponse> {
    const url = `/credit-card-resources`
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'credit-card-resource',
        attributes: {
          'contact-id': contactId
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async CreateCreditCardResourceToken(
    creditCardResourceId: string
  ): Promise<ICreateCreditCardResourceResponse> {
    const url = `/credit-card-resources/${creditCardResourceId}/token`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  // KYC

  public async CreateKYCDocumentCheck(
    contactId: string,
    uploadedDocumentId: string,
    uploadedDocumentBacksideId: string,
    documentType = DOCUMENT_TYPE_DRIVERS_LICENSE
  ): Promise<IKYCCreateDocumentCheckResponse> {
    const url = KYC_DOCUMENT_CHECKS_ENDPOINT
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'kyc-document-checks',
        attributes: {
          'contact-id': contactId,
          'uploaded-document-id': uploadedDocumentId,
          'backside-document-id': uploadedDocumentBacksideId,
          identity: true,
          'identity-photo': true,
          'proof-of-address': false,
          'kyc-document-country': 'US',
          'kyc-document-type': documentType
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async GetKYCDocumentCheck(
    id: string
  ): Promise<IKYCDocumentCheckResource | null> {
    type Response = {
      data: IKYCDocumentCheckResource[]
    }
    const url = `${KYC_DOCUMENT_CHECKS_ENDPOINT}`
    const res: Response = await this.request({ url })
    const check = res.data.filter((check) => {
      return check.id === id
    })
    return check.length > 0 ? check[0] : null
  }

  public async GetAMLCheck(id: string): Promise<IAMLCheckResource | null> {
    type Response = {
      data: IAMLCheckResource[]
    }

    const url = `${KYC_DOCUMENT_CHECKS_ENDPOINT}`
    const res: Response = await this.request({ url })
    const check = res.data.filter((check) => {
      return check.id === id
    })
    return check.length > 0 ? check[0] : null
  }

  public async GetCIPCheck(id: string): Promise<ICIPCheckResource | null> {
    type Response = {
      data: ICIPCheckResource[]
    }

    const url = `${KYC_DOCUMENT_CHECKS_ENDPOINT}`
    const res: Response = await this.request({ url })
    const check = res.data.filter((check) => {
      return check.id === id
    })
    return check.length > 0 ? check[0] : null
  }

  public async TrackKYCProcess(
    contactId: string
  ): Promise<IContactsResponseWithChecks> {
    const url = `${CONTACTS_API_ENDPOINT}?filter[id eq]=${contactId}&include=cip-checks,aml-checks,kyc-document-checks`
    return this.request({ url })
  }

  // QUOTES

  public async RequestQuote(
    request: IQuoteRequest
  ): Promise<IRequestQuoteResponse> {
    const url = QUOTES_API_ENDPOINT
    const method = HTTP_POST_METHOD

    const defaults = {
      hot: false
    }

    const data = {
      data: {
        type: 'quotes',
        attributes: {
          'transaction-type': 'buy',
          ...defaults,
          ...request
        }
      }
    }

    return this.request({ url, method, data })
  }

  public async ExecuteQuote(quoteId: string): Promise<IExecuteQuoteResponse> {
    const url = `${QUOTES_API_ENDPOINT}/${quoteId}/execute?include=trade-settlement`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async GetQuotes() {
    const url = `${QUOTES_API_ENDPOINT}`
    return this.request({ url })
  }

  public async GetQuote(quoteId: string) {
    const url = `${QUOTES_API_ENDPOINT}/${quoteId}`
    return this.request({ url })
  }

  // INTERNAL ASSET TRANSFERS

  public async CreateInternalAssetTransfer(
    request: IInternalAssetTransferRequest
  ) {
    const url = `${INTERNAL_REQUESTS_API_ENDPOINT}`
    const method = HTTP_POST_METHOD

    const data = {
      data: {
        type: 'internal-asset-transfers',
        attributes: {
          ...request
        }
      }
    }

    return this.request({ url, method, data })
  }

  // ASSET DISBURSEMENT

  public async CreateAssetDisbursement(
    assetTransferType: string,
    accountId: string,
    contactId: string,
    assetId: string,
    amount: string,
    walletAddress: string
  ): Promise<ICreateAssetDisbursement> {
    const url = `/asset-disbursements`
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'asset-disbursements',
        attributes: {
          'account-id': accountId,
          'unit-count': amount,
          'hot-transfer': true,
          'asset-transfer-method': {
            'asset-transfer-type': assetTransferType,
            'asset-id': assetId,
            'contact-id': contactId,
            'account-id': accountId,
            'wallet-address': walletAddress,
            'single-use': false,
            'transfer-direction': 'outgoing'
          }
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async GetAssetDisbursementById(
    assetDisbursementId: string
  ): Promise<IGetAssetDisbursement> {
    const url = `/asset-disbursements/${assetDisbursementId}?include=disbursement-authorization`
    return this.request({ url })
  }

  public async GetAssetTransferMethods(): Promise<IGetAssetTransferMethodsResponse> {
    const url = `/asset-transfer-methods`
    return this.request({ url })
  }

  // INTERNAL FIAT TRANSFER

  public async GetFundsTransferMethods(): Promise<IGetFundsTransferMethodsResponse> {
    const url = `/funds-transfer-methods?include=credit-card-resource`
    return this.request({ url })
  }

  public async GetFundsTransferMethodByContactId(
    contactId: string
  ): Promise<IGetFundsTransferMethodsResponse> {
    const url = `/funds-transfer-methods?contact.id=${contactId}&filter[inactive eq]=false&include=bank,contact&page[size]=10000`
    return this.request({ url })
  }

  public async GetAchFundsTransferMethodsByContactId(
    contactId: string
  ): Promise<IGetFundsTransferMethodsResponse> {
    const url = `/funds-transfer-methods?contact.id=${contactId}&filter[funds-transfer-type eq]=ach&filter[inactive eq]=false&include=bank,contact&page[size]=10000`
    return this.request({ url })
  }

  public async GetCardFundsTransferMethodsByContactId(
    contactId: string
  ): Promise<IGetFundsTransferMethodsResponse> {
    const url = `/funds-transfer-methods?contact.id=${contactId}&filter[funds-transfer-type eq]=credit_card&filter[inactive eq]=false&include=bank,contact&page[size]=10000`
    return this.request({ url })
  }

  public async CreateFundsTransferMethod({
    contactId,
    processorToken
  }: {
    contactId: string
    processorToken: string
  }): Promise<ICreateFundsTransferMethodResponse> {
    const url = `/funds-transfer-methods`
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'funds-transfer-method',
        attributes: {
          'contact-id': contactId,
          'plaid-processor-token': processorToken,
          'funds-transfer-type': 'ach',
          'ach-check-type': 'personal',
          'bank-account-name': 'John James Doe',
          'ip-address': '2001:0db8:85a3:0000:0000:8a2e:0370:7334'
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async CreatePendingContribution({
    contactId,
    fundsTransferMethodId,
    amount,
    accountId
  }: {
    contactId: string
    fundsTransferMethodId: string
    amount: string
    accountId: string
  }): Promise<ICreateContributionResponse> {
    const url = `/contributions/token`
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'contributions',
        attributes: {
          amount,
          'account-id': accountId,
          'contact-id': contactId,
          'funds-transfer-method-id': fundsTransferMethodId
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async CreateContribution({
    contactId,
    fundsTransferMethodId,
    amount,
    accountId
  }: {
    contactId: string
    fundsTransferMethodId: string
    amount: string
    accountId: string
  }): Promise<ICreateContributionResponse> {
    const url = `/contributions?include=funds-transfer,account,contact`
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'contributions',
        attributes: {
          amount,
          'account-id': accountId,
          'contact-id': contactId,
          'funds-transfer-method-id': fundsTransferMethodId
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async GetFundsTransferMethod(
    fundsTransferMethodId: string
  ): Promise<IGetFundsTransferMethodsCreditCardResourceIncludedResponse> {
    const url = `/funds-transfer-methods/${fundsTransferMethodId}?include=credit-card-resource`
    return this.request({ url })
  }

  public async GetFundsTransfer(
    fundTransferMethodId: string
  ): Promise<IGetFundsTransferResponse> {
    const url = `/funds-transfers/${fundTransferMethodId}?include=funds-transfer-method`
    return this.request({ url })
  }

  public async GetContribution(id: string): Promise<IGetContributionResponse> {
    const url = `/contributions/${id}?include=funds-transfer,account,contact`
    return this.request({ url })
  }

  // FIAT DISBURSEMENT

  public async CreateFiatDisbursement(
    accountId: string,
    contactId: string,
    currency: string,
    amount: string,
    fundsTransferMethodId: string
  ): Promise<ICreateDisbursement> {
    const url = `/disbursements`
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'disbursements',
        attributes: {
          'account-id': accountId,
          amount: amount,
          'contact-id': contactId,
          'currency-type': currency,
          'funds-transfer-method-id': fundsTransferMethodId
        }
      }
    }
    return this.request({ url, method, data })
  }

  // WEBHOOKS

  public async GetWebhookConfigs() {
    const url = '/webhook-configs'
    return this.request({ url })
  }

  public async CreateWebhookConfig(accountId: string, webhookUrl: string) {
    const url = '/webhook-configs'
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'webhook-configs',
        attributes: {
          url: webhookUrl,
          'account-id': accountId
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async UpdateWebhookConfig(
    webhookId: string,
    accountId: string,
    webhookUrl: string
  ) {
    const url = `/webhook-configs/${webhookId}`
    const method = HTTP_PATCH_METHOD
    const data = {
      data: {
        type: 'webhook-configs',
        attributes: {
          url: webhookUrl,
          'account-id': accountId,
          enabled: true
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async TestWebhookConfig(webhookId: string) {
    const url = `/webhook-configs/${webhookId}/test`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async GetWebhooks() {
    const url = `/webhooks`
    return this.request({ url })
  }

  public async RetryWebhook(webhookId: string) {
    const url = `/webhooks/${webhookId}/retry`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  // SANDBOX

  public async SandboxUpdateQuote(quoteId: string) {
    const url = `${QUOTES_API_ENDPOINT}/${quoteId}/sandbox`
    const method = HTTP_PATCH_METHOD
    const data = {
      data: {
        type: 'quotes',
        attributes: {
          status: 'pending'
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async SandboxApproveAML(
    amlCheckId: string
  ): Promise<ISandboxAMLApproveResponse> {
    const url = `${AML_CHECKS_ENDPOINT}/${amlCheckId}/sandbox/verify`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxVerifyKYCDocument(
    kycDocumentCheckId: string
  ): Promise<ISandboxKYCDocumentChecksApprove> {
    const url = `${KYC_DOCUMENT_CHECKS_ENDPOINT}/${kycDocumentCheckId}/sandbox/verify`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxApproveCIP(
    checkId: string
  ): Promise<ISandboxKYCCIPApprove> {
    const url = `${CIP_CHECKS_ENDPOINT}/${checkId}/sandbox/approve`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxOpenAccount(
    accountId: string
  ): Promise<ISandboxOpenResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}/${accountId}/sandbox/open`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxFund(
    accountId: string,
    amount: number
  ): Promise<ISandboxFundResponse> {
    const url = `${ACCOUNTS_API_ENDPOINT}/${accountId}/sandbox/fund`
    const method = HTTP_POST_METHOD
    const data = {
      data: {
        type: 'accounts',
        attributes: {
          amount
        }
      }
    }
    return this.request({ url, method, data })
  }

  public async SandboxGetCreditCardToken(
    creditCardResourceId: string
  ): Promise<ISandboxGetCreditCardResourceResponse> {
    const url = `/credit-card-resources/${creditCardResourceId}/sandbox`
    return this.request({ url })
  }

  public async SandboxAuthorizeContribution(
    id: string
  ): Promise<ISandboxGetCreditCardResourceResponse> {
    const url = `/contributions/${id}/sandbox/authorize`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxSettleContribution(
    id: string
  ): Promise<ISandboxGetCreditCardResourceResponse> {
    const url = `/contributions/${id}/sandbox/settle`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxCancelContribution(
    id: string
  ): Promise<ISandboxGetCreditCardResourceResponse> {
    const url = `/contributions/${id}/sandbox/cancel`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxCIPDeny(id: string): Promise<any> {
    const url = `/cip-checks/${id}/sandbox/deny`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxAMLDeny(id: string): Promise<any> {
    const url = `/aml-checks/${id}/sandbox/deny`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxKYCDeny(id: string): Promise<any> {
    const url = `/kyc-document-checks/${id}/sandbox/fail`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxFreezeContact(id: string): Promise<any> {
    const url = `/contacts/${id}/sandbox/freeze`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxUnfreezeContact(id: string): Promise<any> {
    const url = `/contacts/${id}/sandbox/unfreeze`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxAuthorizeDisbursement(
    disbursementAuthorizationId: string
  ): Promise<any> {
    const url = `/disbursement-authorizations/${disbursementAuthorizationId}/sandbox/authorize`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxSettleAssetTransfer(
    assetTransferId: string
  ): Promise<any> {
    const url = `/asset-transfers/${assetTransferId}/sandbox/settle`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxClearAssetTransfer(
    assetTransferId: string
  ): Promise<any> {
    const url = `/asset-transfers/${assetTransferId}/sandbox/clear`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxApproveCashTransfer(request: {
    accountCashTransferId: string
  }) {
    const url = `${ACCOUNT_CASH_TRANSFER_API_ENDPOINT}/${request.accountCashTransferId}/sandbox/approve`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxSettleFundsTransfer(fundsTransferId: string) {
    const url = `/funds-transfers/${fundsTransferId}/sandbox/settle`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxClearFundsTransfer(fundsTransferId: string) {
    const url = `/funds-transfers/${fundsTransferId}/sandbox/clear`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }

  public async SandboxReverseFundsTransfer(fundsTransferId: string) {
    const url = `/funds-transfers/${fundsTransferId}/sandbox/reverse`
    const method = HTTP_POST_METHOD
    return this.request({ url, method })
  }
}
