import {
  Check,
  IKYCCreateDocumentCheckResponse
} from './partner/primetrust/interfaces'

export type { Signer } from 'ethers'

export type EmptyProps = Record<string, unknown>

export type Address = string

export type SuccessResponse = {
  success: true
}

export enum PolkadotNetwork {
  Acala = 'acala',
  Mandala = 'mandala'
}

// Fin Primitives

export type Fees = {
  network: number
  onRamp: number
  accountSetup: AccountSetupFees
}

export type AccountSetupFees = {
  firstTimeVerification: number
  firstTimeACH: number
  firstTimeCard: number
}

// Api Types

export type PendingChecksResponse = {
  pendingChecks: Check[]
  accountStatus: string
}

export type PendingChecksError = { error: string; data?: any }

export type KYCError = {
  status: number
  title: string
  source: {
    pointer: string
  }
  detail: string
}

export type CreateKYCDocumentCheckResponse = {
  documentCheck: IKYCCreateDocumentCheckResponse
}

export type AccountQuery = {
  accountId: string
}

export type CreateAccountData = {
  accountId: string
  agreement: string
}

export type CreateSocureData = {
  documentUuid: string
}

export type CreateDocumentData = {
  data: IKYCCreateDocumentCheckResponse
}

export type KYCRequiredActions = {
  code: string
  description: string
}

export type KYCAccountData = {
  account: {
    status: AccountStatus
  }
  contacts: {
    kycRequiredActions: { code: string; description: string }[]
    cipCleared: boolean
    amlCleared: boolean
    documentsCleared: boolean
  }
  checks: Check[]
}
export type PlaceOrderFromCardContributionRequest = {
  contributionId: string
  amount: number
  walletAddress: string
  tokenCurrency: TokenCurrency
  fees: Fees | undefined
  exchangeRate: number
}

export type CreateACHPaymentRequest = {
  email: string
  amount: number
  legalName: string
  fundsTransferMethodId: string
  tokenCurrency: TokenCurrency
  walletAddress: string
  fees: Fees | undefined
  exchangeRate: number
}

export type CreateACHFundsTransferMethod = {
  email: string
  amount: number
  legalName: string
  plaidAccountId: string
  walletAddress: string
}

export type FundsTransferMethod = {
  fundsTransferMethodId: string
}

export type ApiSuccess<T> = {
  success: boolean
  data: T
}

export type ApiError<T> = {
  error: string
  data?: T
}

export enum AccountStatus {
  Pending = 'pending',
  Closed = 'closed',
  Opened = 'opened'
}

export enum PaymentMethod {
  None,
  Card,
  ACH
}

export enum CardType {
  VISA,
  MASTERCARD,
  UNKNOWN
}

export type Card = {
  cardType: CardType
  expirationDate: string
  lastFourDigits: string
  fundTransferMethodId: string
}

export type AchAccount = {
  bankAccountType: string
  bankName: string
  lastFourDigits: string
  fundTransferMethodId: string
}

// CoinGecko Price Feed

export type GeckoV3SimplePrice = {
  polkadot: { usd: number }
  'acala-dollar': { usd: number }
  acala: { usd: number }
  tether: { usd: number }
}

export type OrderEventFulfilledBuy = {
  id: number
  paymentMethod: string
  ptFundsTransferId: string
  buyerOrderId: string
  createdAt: Date
  amountUSD: number
  customerId: number
  destinationAddress: string
}

export type UploadDocumentRequest = {
  frontImgUrl: string
  backImgUrl: string
  frontImgKey: string
  backImgKey: string
}

export type GetFeesRequest = {
  amount: number
  paymentMethod: PaymentMethod
  tokenCurrency: TokenCurrency
  useDefaultNetworkFees: boolean
}

export type GetNetworkFeesRequest = {
  amount: number
  tokenCurrency: TokenCurrency
}

export type GetBuyerOrderDetailsRequest = {
  orderId: string
}

export enum TokenCurrency {
  aUSD = 'aUSD'
}

export type OrderDetailsResponse = {
  sourceCurrency: string
  sourceCurrencyAmount: number
  purchaseCryptoCurrency: string
  purchaseCryptoCurrencyAmount: number
  receiverAddress: string
  createdAt: string
  paymentMethod: PaymentMethod
  paymentMethodDetails: {
    bankAccountType: string
    bankName: string
    lastFourDigits: string
    creditCardType: CardType
  }
  exchangeRate: number
  fees: Fees
  txnHash: string
}

export type ReceiptDetails = {
  fiatAmount: number
  walletAddress: string
  purchaseCryptoCurrency: string
  purchaseCryptoCurrencyAmount: number
  orderId: string
  orderTimestamp: string
  exchangeRate: number
  fees: Fees
  paymentMethod: PaymentMethod
  lastFourDigits: string
  cardType?: CardType
  bankAccountType?: string
  bankName?: string
  txnHash?: string
}
